/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Recon Backend API
 * API for the Recon Backend service
 * OpenAPI spec version: 0.3.29
 */
import { useMutation, useQuery } from "@tanstack/vue-query";
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType,
} from "@tanstack/vue-query";
import { unref } from "vue";
import type {
  HTTPValidationError,
  ImportProgram200,
  ImportProgramParams,
  RunSqlQueryParams,
  ScanResponse,
  SubscriptionCodeCreate,
  SubscriptionCodeResponse,
  SubscriptionCodeUpdate,
  TeamResponse,
  UserResponse,
} from "../reconBackendAPI.schemas";
import { customFetch } from "../../utils/customFetch";
import { mutator } from "../../utils/customQueryOptions";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Import Program
 */
export type importProgramResponse = {
  data: ImportProgram200;
  status: number;
  headers: Headers;
};

export const getImportProgramUrl = (params: ImportProgramParams) => {
  const normalizedParams = new URLSearchParams();

  Object.entries(params || {}).forEach(([key, value]) => {
    if (value !== undefined) {
      normalizedParams.append(key, value === null ? "null" : value.toString());
    }
  });

  return normalizedParams.size
    ? `http://localhost:5001/v1/admin/import_program?${normalizedParams.toString()}`
    : `http://localhost:5001/v1/admin/import_program`;
};

export const importProgram = async (
  params: ImportProgramParams,
  options?: RequestInit,
): Promise<importProgramResponse> => {
  return customFetch<Promise<importProgramResponse>>(
    getImportProgramUrl(params),
    {
      ...options,
      method: "POST",
    },
  );
};

export const getImportProgramMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof importProgram>>,
    TError,
    { params: ImportProgramParams },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof importProgram>>,
  TError,
  { params: ImportProgramParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof importProgram>>,
    { params: ImportProgramParams }
  > = (props) => {
    const { params } = props ?? {};

    return importProgram(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ImportProgramMutationResult = NonNullable<
  Awaited<ReturnType<typeof importProgram>>
>;

export type ImportProgramMutationError = HTTPValidationError;

/**
 * @summary Import Program
 */
export const useImportProgram = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof importProgram>>,
    TError,
    { params: ImportProgramParams },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationReturnType<
  Awaited<ReturnType<typeof importProgram>>,
  TError,
  { params: ImportProgramParams },
  TContext
> => {
  const mutationOptions = getImportProgramMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get Users
 */
export type getUsersResponse = {
  data: UserResponse[];
  status: number;
  headers: Headers;
};

export const getGetUsersUrl = () => {
  return `http://localhost:5001/v1/admin/users`;
};

export const getUsers = async (
  options?: RequestInit,
): Promise<getUsersResponse> => {
  return customFetch<Promise<getUsersResponse>>(getGetUsersUrl(), {
    ...options,
    method: "GET",
  });
};

export const getGetUsersQueryKey = () => {
  return ["http:", "localhost:5001", "v1", "admin", "users"] as const;
};

export const useGetUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof getUsers>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData>
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetUsersQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsers>>> = () =>
    getUsers(requestOptions);

  const customOptions = mutator({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getUsers>>,
    TError,
    TData
  >;
};

export type GetUsersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUsers>>
>;
export type GetUsersQueryError = unknown;

/**
 * @summary Get Users
 */

export function useGetUsers<
  TData = Awaited<ReturnType<typeof getUsers>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData>
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = useGetUsersQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}

/**
 * @summary Get Teams
 */
export type getTeamsResponse = {
  data: TeamResponse[];
  status: number;
  headers: Headers;
};

export const getGetTeamsUrl = () => {
  return `http://localhost:5001/v1/admin/teams`;
};

export const getTeams = async (
  options?: RequestInit,
): Promise<getTeamsResponse> => {
  return customFetch<Promise<getTeamsResponse>>(getGetTeamsUrl(), {
    ...options,
    method: "GET",
  });
};

export const getGetTeamsQueryKey = () => {
  return ["http:", "localhost:5001", "v1", "admin", "teams"] as const;
};

export const useGetTeamsQueryOptions = <
  TData = Awaited<ReturnType<typeof getTeams>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getTeams>>, TError, TData>
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetTeamsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTeams>>> = () =>
    getTeams(requestOptions);

  const customOptions = mutator({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getTeams>>,
    TError,
    TData
  >;
};

export type GetTeamsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTeams>>
>;
export type GetTeamsQueryError = unknown;

/**
 * @summary Get Teams
 */

export function useGetTeams<
  TData = Awaited<ReturnType<typeof getTeams>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getTeams>>, TError, TData>
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = useGetTeamsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}

/**
 * @summary Delete User
 */
export type deleteUserResponse = {
  data: unknown;
  status: number;
  headers: Headers;
};

export const getDeleteUserUrl = (userId: string) => {
  return `http://localhost:5001/v1/admin/users/${userId}`;
};

export const deleteUser = async (
  userId: string,
  options?: RequestInit,
): Promise<deleteUserResponse> => {
  return customFetch<Promise<deleteUserResponse>>(getDeleteUserUrl(userId), {
    ...options,
    method: "DELETE",
  });
};

export const getDeleteUserMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUser>>,
    TError,
    { userId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUser>>,
  TError,
  { userId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUser>>,
    { userId: string }
  > = (props) => {
    const { userId } = props ?? {};

    return deleteUser(userId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUser>>
>;

export type DeleteUserMutationError = HTTPValidationError;

/**
 * @summary Delete User
 */
export const useDeleteUser = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUser>>,
    TError,
    { userId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationReturnType<
  Awaited<ReturnType<typeof deleteUser>>,
  TError,
  { userId: string },
  TContext
> => {
  const mutationOptions = getDeleteUserMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Reset Database
 */
export type resetDatabaseResponse = {
  data: unknown;
  status: number;
  headers: Headers;
};

export const getResetDatabaseUrl = () => {
  return `http://localhost:5001/v1/admin/reset_database`;
};

export const resetDatabase = async (
  options?: RequestInit,
): Promise<resetDatabaseResponse> => {
  return customFetch<Promise<resetDatabaseResponse>>(getResetDatabaseUrl(), {
    ...options,
    method: "POST",
  });
};

export const getResetDatabaseMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetDatabase>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof resetDatabase>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof resetDatabase>>,
    void
  > = () => {
    return resetDatabase(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResetDatabaseMutationResult = NonNullable<
  Awaited<ReturnType<typeof resetDatabase>>
>;

export type ResetDatabaseMutationError = unknown;

/**
 * @summary Reset Database
 */
export const useResetDatabase = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetDatabase>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationReturnType<
  Awaited<ReturnType<typeof resetDatabase>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getResetDatabaseMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get Importable Programs
 */
export type getImportableProgramsResponse = {
  data: string[];
  status: number;
  headers: Headers;
};

export const getGetImportableProgramsUrl = () => {
  return `http://localhost:5001/v1/admin/import_program`;
};

export const getImportablePrograms = async (
  options?: RequestInit,
): Promise<getImportableProgramsResponse> => {
  return customFetch<Promise<getImportableProgramsResponse>>(
    getGetImportableProgramsUrl(),
    {
      ...options,
      method: "GET",
    },
  );
};

export const getGetImportableProgramsQueryKey = () => {
  return ["http:", "localhost:5001", "v1", "admin", "import_program"] as const;
};

export const useGetImportableProgramsQueryOptions = <
  TData = Awaited<ReturnType<typeof getImportablePrograms>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getImportablePrograms>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetImportableProgramsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getImportablePrograms>>
  > = () => getImportablePrograms(requestOptions);

  const customOptions = mutator({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getImportablePrograms>>,
    TError,
    TData
  >;
};

export type GetImportableProgramsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getImportablePrograms>>
>;
export type GetImportableProgramsQueryError = unknown;

/**
 * @summary Get Importable Programs
 */

export function useGetImportablePrograms<
  TData = Awaited<ReturnType<typeof getImportablePrograms>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getImportablePrograms>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = useGetImportableProgramsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}

/**
 * Get all pending scans (admin only)
 * @summary Get Pending Scan Route
 */
export type getPendingScanRouteResponse = {
  data: ScanResponse[];
  status: number;
  headers: Headers;
};

export const getGetPendingScanRouteUrl = () => {
  return `http://localhost:5001/v1/admin/pending_scans`;
};

export const getPendingScanRoute = async (
  options?: RequestInit,
): Promise<getPendingScanRouteResponse> => {
  return customFetch<Promise<getPendingScanRouteResponse>>(
    getGetPendingScanRouteUrl(),
    {
      ...options,
      method: "GET",
    },
  );
};

export const getGetPendingScanRouteQueryKey = () => {
  return ["http:", "localhost:5001", "v1", "admin", "pending_scans"] as const;
};

export const useGetPendingScanRouteQueryOptions = <
  TData = Awaited<ReturnType<typeof getPendingScanRoute>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getPendingScanRoute>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPendingScanRouteQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPendingScanRoute>>
  > = () => getPendingScanRoute(requestOptions);

  const customOptions = mutator({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getPendingScanRoute>>,
    TError,
    TData
  >;
};

export type GetPendingScanRouteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPendingScanRoute>>
>;
export type GetPendingScanRouteQueryError = unknown;

/**
 * @summary Get Pending Scan Route
 */

export function useGetPendingScanRoute<
  TData = Awaited<ReturnType<typeof getPendingScanRoute>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getPendingScanRoute>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = useGetPendingScanRouteQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}

/**
 * Approve a pending scan (admin only)
 * @summary Approve Scan
 */
export type approveScanResponse = {
  data: ScanResponse;
  status: number;
  headers: Headers;
};

export const getApproveScanUrl = (scanId: number) => {
  return `http://localhost:5001/v1/admin/pending_scans/${scanId}/approve`;
};

export const approveScan = async (
  scanId: number,
  options?: RequestInit,
): Promise<approveScanResponse> => {
  return customFetch<Promise<approveScanResponse>>(getApproveScanUrl(scanId), {
    ...options,
    method: "POST",
  });
};

export const getApproveScanMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof approveScan>>,
    TError,
    { scanId: number },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof approveScan>>,
  TError,
  { scanId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof approveScan>>,
    { scanId: number }
  > = (props) => {
    const { scanId } = props ?? {};

    return approveScan(scanId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ApproveScanMutationResult = NonNullable<
  Awaited<ReturnType<typeof approveScan>>
>;

export type ApproveScanMutationError = HTTPValidationError;

/**
 * @summary Approve Scan
 */
export const useApproveScan = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof approveScan>>,
    TError,
    { scanId: number },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationReturnType<
  Awaited<ReturnType<typeof approveScan>>,
  TError,
  { scanId: number },
  TContext
> => {
  const mutationOptions = getApproveScanMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Deny a pending scan (admin only)
 * @summary Deny Scan
 */
export type denyScanResponse = {
  data: ScanResponse;
  status: number;
  headers: Headers;
};

export const getDenyScanUrl = (scanId: number) => {
  return `http://localhost:5001/v1/admin/pending_scans/${scanId}/deny`;
};

export const denyScan = async (
  scanId: number,
  options?: RequestInit,
): Promise<denyScanResponse> => {
  return customFetch<Promise<denyScanResponse>>(getDenyScanUrl(scanId), {
    ...options,
    method: "POST",
  });
};

export const getDenyScanMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof denyScan>>,
    TError,
    { scanId: number },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof denyScan>>,
  TError,
  { scanId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof denyScan>>,
    { scanId: number }
  > = (props) => {
    const { scanId } = props ?? {};

    return denyScan(scanId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DenyScanMutationResult = NonNullable<
  Awaited<ReturnType<typeof denyScan>>
>;

export type DenyScanMutationError = HTTPValidationError;

/**
 * @summary Deny Scan
 */
export const useDenyScan = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof denyScan>>,
    TError,
    { scanId: number },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationReturnType<
  Awaited<ReturnType<typeof denyScan>>,
  TError,
  { scanId: number },
  TContext
> => {
  const mutationOptions = getDenyScanMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get all subscription codes (admin only)
 * @summary Get Subscription Codes
 */
export type getSubscriptionCodesResponse = {
  data: SubscriptionCodeResponse[];
  status: number;
  headers: Headers;
};

export const getGetSubscriptionCodesUrl = () => {
  return `http://localhost:5001/v1/admin/subscription_codes`;
};

export const getSubscriptionCodes = async (
  options?: RequestInit,
): Promise<getSubscriptionCodesResponse> => {
  return customFetch<Promise<getSubscriptionCodesResponse>>(
    getGetSubscriptionCodesUrl(),
    {
      ...options,
      method: "GET",
    },
  );
};

export const getGetSubscriptionCodesQueryKey = () => {
  return [
    "http:",
    "localhost:5001",
    "v1",
    "admin",
    "subscription_codes",
  ] as const;
};

export const useGetSubscriptionCodesQueryOptions = <
  TData = Awaited<ReturnType<typeof getSubscriptionCodes>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getSubscriptionCodes>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSubscriptionCodesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSubscriptionCodes>>
  > = () => getSubscriptionCodes(requestOptions);

  const customOptions = mutator({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof getSubscriptionCodes>>,
    TError,
    TData
  >;
};

export type GetSubscriptionCodesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSubscriptionCodes>>
>;
export type GetSubscriptionCodesQueryError = unknown;

/**
 * @summary Get Subscription Codes
 */

export function useGetSubscriptionCodes<
  TData = Awaited<ReturnType<typeof getSubscriptionCodes>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getSubscriptionCodes>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = useGetSubscriptionCodesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}

/**
 * Create a new subscription code (admin only)
 * @summary Create Subscription Code
 */
export type createSubscriptionCodeResponse = {
  data: SubscriptionCodeResponse;
  status: number;
  headers: Headers;
};

export const getCreateSubscriptionCodeUrl = () => {
  return `http://localhost:5001/v1/admin/subscription_codes`;
};

export const createSubscriptionCode = async (
  subscriptionCodeCreate: SubscriptionCodeCreate,
  options?: RequestInit,
): Promise<createSubscriptionCodeResponse> => {
  return customFetch<Promise<createSubscriptionCodeResponse>>(
    getCreateSubscriptionCodeUrl(),
    {
      ...options,
      method: "POST",
      headers: { "Content-Type": "application/json", ...options?.headers },
      body: JSON.stringify(subscriptionCodeCreate),
    },
  );
};

export const getCreateSubscriptionCodeMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createSubscriptionCode>>,
    TError,
    { data: SubscriptionCodeCreate },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createSubscriptionCode>>,
  TError,
  { data: SubscriptionCodeCreate },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createSubscriptionCode>>,
    { data: SubscriptionCodeCreate }
  > = (props) => {
    const { data } = props ?? {};

    return createSubscriptionCode(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateSubscriptionCodeMutationResult = NonNullable<
  Awaited<ReturnType<typeof createSubscriptionCode>>
>;
export type CreateSubscriptionCodeMutationBody = SubscriptionCodeCreate;
export type CreateSubscriptionCodeMutationError = HTTPValidationError;

/**
 * @summary Create Subscription Code
 */
export const useCreateSubscriptionCode = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createSubscriptionCode>>,
    TError,
    { data: SubscriptionCodeCreate },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationReturnType<
  Awaited<ReturnType<typeof createSubscriptionCode>>,
  TError,
  { data: SubscriptionCodeCreate },
  TContext
> => {
  const mutationOptions = getCreateSubscriptionCodeMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Update a subscription code (admin only)
 * @summary Update Subscription Code
 */
export type updateSubscriptionCodeResponse = {
  data: SubscriptionCodeResponse;
  status: number;
  headers: Headers;
};

export const getUpdateSubscriptionCodeUrl = (code: string) => {
  return `http://localhost:5001/v1/admin/subscription_codes/${code}`;
};

export const updateSubscriptionCode = async (
  code: string,
  subscriptionCodeUpdate: SubscriptionCodeUpdate,
  options?: RequestInit,
): Promise<updateSubscriptionCodeResponse> => {
  return customFetch<Promise<updateSubscriptionCodeResponse>>(
    getUpdateSubscriptionCodeUrl(code),
    {
      ...options,
      method: "PATCH",
      headers: { "Content-Type": "application/json", ...options?.headers },
      body: JSON.stringify(subscriptionCodeUpdate),
    },
  );
};

export const getUpdateSubscriptionCodeMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSubscriptionCode>>,
    TError,
    { code: string; data: SubscriptionCodeUpdate },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateSubscriptionCode>>,
  TError,
  { code: string; data: SubscriptionCodeUpdate },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateSubscriptionCode>>,
    { code: string; data: SubscriptionCodeUpdate }
  > = (props) => {
    const { code, data } = props ?? {};

    return updateSubscriptionCode(code, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateSubscriptionCodeMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateSubscriptionCode>>
>;
export type UpdateSubscriptionCodeMutationBody = SubscriptionCodeUpdate;
export type UpdateSubscriptionCodeMutationError = HTTPValidationError;

/**
 * @summary Update Subscription Code
 */
export const useUpdateSubscriptionCode = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSubscriptionCode>>,
    TError,
    { code: string; data: SubscriptionCodeUpdate },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationReturnType<
  Awaited<ReturnType<typeof updateSubscriptionCode>>,
  TError,
  { code: string; data: SubscriptionCodeUpdate },
  TContext
> => {
  const mutationOptions = getUpdateSubscriptionCodeMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Delete a subscription code (admin only)
 * @summary Delete Subscription Code
 */
export type deleteSubscriptionCodeResponse = {
  data: unknown;
  status: number;
  headers: Headers;
};

export const getDeleteSubscriptionCodeUrl = (code: string) => {
  return `http://localhost:5001/v1/admin/subscription_codes/${code}`;
};

export const deleteSubscriptionCode = async (
  code: string,
  options?: RequestInit,
): Promise<deleteSubscriptionCodeResponse> => {
  return customFetch<Promise<deleteSubscriptionCodeResponse>>(
    getDeleteSubscriptionCodeUrl(code),
    {
      ...options,
      method: "DELETE",
    },
  );
};

export const getDeleteSubscriptionCodeMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSubscriptionCode>>,
    TError,
    { code: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteSubscriptionCode>>,
  TError,
  { code: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteSubscriptionCode>>,
    { code: string }
  > = (props) => {
    const { code } = props ?? {};

    return deleteSubscriptionCode(code, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteSubscriptionCodeMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteSubscriptionCode>>
>;

export type DeleteSubscriptionCodeMutationError = HTTPValidationError;

/**
 * @summary Delete Subscription Code
 */
export const useDeleteSubscriptionCode = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSubscriptionCode>>,
    TError,
    { code: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationReturnType<
  Awaited<ReturnType<typeof deleteSubscriptionCode>>,
  TError,
  { code: string },
  TContext
> => {
  const mutationOptions = getDeleteSubscriptionCodeMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Impersonate a user (admin only)
 * @summary Impersonate User
 */
export type impersonateUserResponse = {
  data: unknown;
  status: number;
  headers: Headers;
};

export const getImpersonateUserUrl = (userId: string) => {
  return `http://localhost:5001/v1/admin/impersonate/${userId}`;
};

export const impersonateUser = async (
  userId: string,
  options?: RequestInit,
): Promise<impersonateUserResponse> => {
  return customFetch<Promise<impersonateUserResponse>>(
    getImpersonateUserUrl(userId),
    {
      ...options,
      method: "POST",
    },
  );
};

export const getImpersonateUserMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof impersonateUser>>,
    TError,
    { userId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof impersonateUser>>,
  TError,
  { userId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof impersonateUser>>,
    { userId: string }
  > = (props) => {
    const { userId } = props ?? {};

    return impersonateUser(userId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ImpersonateUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof impersonateUser>>
>;

export type ImpersonateUserMutationError = HTTPValidationError;

/**
 * @summary Impersonate User
 */
export const useImpersonateUser = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof impersonateUser>>,
    TError,
    { userId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationReturnType<
  Awaited<ReturnType<typeof impersonateUser>>,
  TError,
  { userId: string },
  TContext
> => {
  const mutationOptions = getImpersonateUserMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Stop impersonating a user (admin only)
 * @summary Stop Impersonating
 */
export type stopImpersonatingResponse = {
  data: unknown;
  status: number;
  headers: Headers;
};

export const getStopImpersonatingUrl = () => {
  return `http://localhost:5001/v1/admin/impersonate`;
};

export const stopImpersonating = async (
  options?: RequestInit,
): Promise<stopImpersonatingResponse> => {
  return customFetch<Promise<stopImpersonatingResponse>>(
    getStopImpersonatingUrl(),
    {
      ...options,
      method: "POST",
    },
  );
};

export const getStopImpersonatingMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stopImpersonating>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof stopImpersonating>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof stopImpersonating>>,
    void
  > = () => {
    return stopImpersonating(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type StopImpersonatingMutationResult = NonNullable<
  Awaited<ReturnType<typeof stopImpersonating>>
>;

export type StopImpersonatingMutationError = unknown;

/**
 * @summary Stop Impersonating
 */
export const useStopImpersonating = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stopImpersonating>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationReturnType<
  Awaited<ReturnType<typeof stopImpersonating>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getStopImpersonatingMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Run arbitrary SQL query (admin only)
 * @summary Run Sql Query
 */
export type runSqlQueryResponse = {
  data: unknown;
  status: number;
  headers: Headers;
};

export const getRunSqlQueryUrl = (params: RunSqlQueryParams) => {
  const normalizedParams = new URLSearchParams();

  Object.entries(params || {}).forEach(([key, value]) => {
    if (value !== undefined) {
      normalizedParams.append(key, value === null ? "null" : value.toString());
    }
  });

  return normalizedParams.size
    ? `http://localhost:5001/v1/admin/sql?${normalizedParams.toString()}`
    : `http://localhost:5001/v1/admin/sql`;
};

export const runSqlQuery = async (
  params: RunSqlQueryParams,
  options?: RequestInit,
): Promise<runSqlQueryResponse> => {
  return customFetch<Promise<runSqlQueryResponse>>(getRunSqlQueryUrl(params), {
    ...options,
    method: "POST",
  });
};

export const getRunSqlQueryMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof runSqlQuery>>,
    TError,
    { params: RunSqlQueryParams },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof runSqlQuery>>,
  TError,
  { params: RunSqlQueryParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof runSqlQuery>>,
    { params: RunSqlQueryParams }
  > = (props) => {
    const { params } = props ?? {};

    return runSqlQuery(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RunSqlQueryMutationResult = NonNullable<
  Awaited<ReturnType<typeof runSqlQuery>>
>;

export type RunSqlQueryMutationError = HTTPValidationError;

/**
 * @summary Run Sql Query
 */
export const useRunSqlQuery = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof runSqlQuery>>,
    TError,
    { params: RunSqlQueryParams },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationReturnType<
  Awaited<ReturnType<typeof runSqlQuery>>,
  TError,
  { params: RunSqlQueryParams },
  TContext
> => {
  const mutationOptions = getRunSqlQueryMutationOptions(options);

  return useMutation(mutationOptions);
};
